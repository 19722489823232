@import '../../styles/variables';

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 64px;
  width: 100%;

  padding: 0 16px;
  border-bottom: 1px solid $on-surface-color-50;

  color: $on-surface-color;

  cursor: pointer;

  &:hover {
    background-color: $primary-color-hover;
    color: $on-primary-color;
  }
}

.item > .item {
  border-bottom: none;
}

.locked {
  color: $on-surface-color-20;
  pointer-events: none;
}

.separator {
  background-color: $primary-color;
  color: $on-primary-color;
  font-weight: 600;

  i {
    display: none;
  }
}

.caption {
  display: block;
  font-size: $caption;
  color: $on-surface-color-50;
}

.accordion {
  background-color: #eee;
  cursor: pointer;
  text-align: left;
  transition: 0.4s;
}

.disabled {
  pointer-events: none;
}