@import '../../styles/variables';

.container {
  position: relative;
  z-index: 10;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 56px;

  padding: 16px;
  box-shadow: $box-shadow;

  background-color: $primary-color;
  color: $on-primary-color;

  i {
    cursor: pointer;
  }
}

.hide {
  visibility: hidden;
}

.closeButton {
  transition: transform 0.2s ease;
  &:hover {
    transform: rotate(-90deg);
  }
}