@import '../../styles/variables';

.container {
  position: relative;
  z-index: 10;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 56px;

  padding: 16px;
  box-shadow: $box-shadow;

  background-color: $surface-color;

  &:hover {
    z-index: 1002; // don't ask why
  }

  .iconContainer {
    display: flex;
  }

  i {
    font-size: $icon-3;
    cursor: pointer;

    &:hover {
      color: $primary-color-hover;
    }
  }

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      border: 1px solid $primary-color-hover;
    }
  }

  .iconContainerItem {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-right: 20px;

    &:after {
      content: "";
      position: absolute;
      bottom: -30px;
      padding: 5px;
      border-radius: 5px;
      background-color: $surface-color;
      left: 50%;
      transform: translateX(-50%);
      font-family: FFDINStd, sans-serif;
      font-size: 14px;
      pointer-events: none;
      opacity: 0;
      margin-bottom: 0;
      box-shadow: $box-shadow;
      transition: opacity 0.2s ease-out, margin-bottom 0.2s ease-out;
    }

    &:hover {
      &:after {
        opacity: 1;
        margin-bottom: -7px;
        color: $primary-color-hover;
      }
    }

    &[data-item='menu'] {
      &:after {
        content: "Menü";
      }
    }
    &[data-item='library'] {
      &:after {
        content: "Arbeitsplatz";
      }
    }
    &[data-item='notepad'] {
      &:after {
        content: "Notizblock";
      }
    }
    &[data-item='clipboard'] {
      &:after {
        content: "Zwischenablage";
      }
    }
    &[data-item='joker'] {
      &:after {
        content: "Joker";
      }
    }
  }
}

.inactive {
  opacity: 0.6;
  pointer-events: none;
}

.noUse {
  filter: grayscale(100%);
  pointer-events: none;
}

.selected {
  color: $primary-color;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: -7px;
    left: -8px;
    bottom: -7px;
    right: -6px;
    border-radius: 50%;
    z-index: -1;
    border: 2px solid $primary-color-hover;
    box-shadow: 0px 0px 0px 5px rgba($primary-color-hover, 0.2);

    -webkit-animation: pulsate-fwd 1s ease-in-out infinite both;
    animation: pulsate-fwd 1s ease-in-out infinite both;
  }
}

@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
