@import '../../styles/variables';

.container {
  position: absolute;
  top: 22px;
  left: $padding;
  right: $padding;
  bottom: 22px;

  display: flex;
  flex-direction: column;

  background-color: $surface-color;

  @include max-width-down {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.content {
  position: relative;
  flex: 1;

  width: 100%;
  max-width: $content-max-width;

  margin: 0 auto;
  padding: $padding;

  overflow: auto;

  ul, ol {
    margin: 16px 0;
  }

  ul {
    padding: 0;
    list-style: none;
  }

  h2 {
    margin: 24px 0 10px 0;
    text-align: left;
  }

  h3 + * {
    margin-top: 0;
  }

  img {
    width: 100%;
  }
}