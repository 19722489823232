@import '../../styles/variables';

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  max-width: $content-max-width;

  margin: 0 auto;
  padding: 24px $padding 24px $padding;

  overflow: auto;
}

.minifiedContent {
  justify-content: flex-start;
}

.infobox {
  margin-bottom: 24px;
}

.hideHighscore {
  span {
    display: none;
  }
}