@import '../../styles/variables';

.container {
  display: inline-block;

  position: relative;
  z-index: 10;

  font-size: 16px;

  width: 2em;
  height: 2em;

  margin: 0 5px;

  cursor: pointer;

  &:hover .ring {
    background-color: $primary-color-hover;
    animation: none;
  }

  &:active .ring {
    animation: stop-pulsate 0.3s;
  }
}

.circle {
  position: absolute;
  left: 50%;
  top: 50%;

  width: 2em;
  height: 2em;

  transform: translate3d(-50%, -50%, 1px);
  transition: opacity .2s ease-in, transform .1s ease-out;

  background-color: $primary-color;
  border-radius: 50%;
  opacity: 0.8;

  &:hover {
    opacity: 1;
    background-color: $primary-color-hover;
  }

  &:active {
    transform: translate3d(-50%, -50%, 1px) scale3d(0.85, 0.85, 0.85);
  }
}

.ring {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;

  width: 2em;
  height: 2em;

  transform: translate3d(-50%, -50%, 1px);

  border-radius: 50%;
  border: 1px solid $primary-color;

  animation: pulsate 3s ease-out infinite;

  opacity: 0;
}

@keyframes pulsate {
  0% {
    transform: translate3d(-50%, -50%, 1px) scale3d(1, 1, 1);
    opacity: 0.8;
  }
  45% {
    transform: translate3d(-50%, -50%, 1px) scale3d(1.75, 1.75, 1.75);
    opacity: 0;
  }
}

@keyframes stop-pulsate {
  0% {
    opacity: 1;
  }
  100% {
    transform: translate3d(-50%, -50%, 1px) scale3d(2, 2, 2);
    opacity: 0;
  }
}