$primary-color: #702AAC;
$primary-color-80: transparentize($primary-color, 0.2);
$primary-color-50: transparentize($primary-color, 0.5);
$primary-color-hover: #C86FFF;

$correct-color: #4DD57B;
$neutral-color: #F7AB18;
$incorrect-color: #E20513;

$background-color:#F5F5F5;
$container-color: #FFFFFF;
$surface-color: #FFFFFF;

$on-primary-color: #FFFFFF;
$on-primary-color-50: transparentize($on-primary-color, 0.5);
$on-primary-color-20: transparentize($on-primary-color, 0.8);

$on-surface-color: #333333;
$on-surface-color-50: transparentize($on-surface-color, 0.5);
$on-surface-color-20: transparentize($on-surface-color, 0.8);

$box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
$no-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0);