@import '../../styles/variables';

.container {
  width: 100%;
  max-width: $element-max-width;

  padding: $padding;
  box-shadow: $box-shadow;

  button {
    padding: 14px;

    background-color: $surface-color;
    box-shadow: $box-shadow;
    border-radius: 5px;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    color: $primary-color;
    touch-action: manipulation; // https://stackoverflow.com/questions/10614481/disable-double-tap-zoom-option-in-browser-on-touch-devices/53236027#53236027

    cursor: pointer;

    transition: box-shadow 0.15s ease, transform 0.15s ease;

    &:hover {
      background-color: $primary-color-hover;
      color: $on-primary-color;
    }

    &:active {
      transform: translateY(2px);
      box-shadow: $no-box-shadow;
    }
  }

  .primary {
    background-color: $primary-color;
    color: $on-primary-color;
    font-size: 20px;
    margin-top: 10px;
  }
}

.display {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  height: 56px;
  padding: 6px $padding;

  background-color: $surface-color;
  box-shadow: $box-shadow;
  border-radius: 5px;

  input {
    width: 100%;

    font-size: 32px;
    font-weight: 600;
    color: $on-surface-color;
    text-align: right;

    outline: none;
    border: none;
  }
}

.buttonsContainer {
  display: grid;
  grid-template-areas:  "g f f"
                        "a b c"
                        "a b c"
                        "a b c"
                        "e e c";
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 10px;

  margin-top: 10px;
  border-bottom: 1px solid #00000026;
  padding-bottom: 10px;

  button {
    background-color: $surface-color;
    box-shadow: $box-shadow;
    border-radius: 5px;

    font-size: 20px;
    padding: 12px;
    font-weight: 600;
    color: $primary-color;

    cursor: pointer;

    transition: box-shadow 0.15s ease, transform 0.15s ease;

    &:hover {
      background-color: $primary-color-hover;
      color: $on-primary-color;
    }

    &:active {
      transform: translateY(2px);
      box-shadow: $no-box-shadow;
    }
  }

  .primary {
    background-color: $primary-color;
    color: $on-primary-color;
  }

  .dense {
    font-size: 17px;
    padding: 8px;
  }
}

.optionButtonsContainer {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin: 10px -5px 0 -5px;

  button {
    font-size: 18px;
    padding: 10px;
    flex: 1 1 100%;
    margin: 0 5px;
  }
}
