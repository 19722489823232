@import '../../styles/variables';

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: rgba(51, 51, 51, 0.5);

  overflow: scroll;
}

.calculator {
  border-radius: $border-radius;
  background-color: $background-color;
}

.hideButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;

  background-color: $primary-color;
  box-shadow: $box-shadow;
  border-radius: 5px;

  font-size: 26px;
  font-weight: 600;
  color: $surface-color;
  width: 100%;
  width: calc(100% - 30px);
  margin: 10px auto;
  cursor: pointer;

  transition: box-shadow 0.15s ease, transform 0.15s ease;

  &:hover {
    background-color: $primary-color-hover;
    color: $on-primary-color;
  }

  &:active {
    transform: translateY(2px);
    box-shadow: $no-box-shadow;
  }
}
