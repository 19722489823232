@import '../../styles/variables';

.container {
  width: 100%;

  margin: 0;
  padding: 0;

  background-color: $surface-color;
  list-style: none;

  border-radius: $border-radius;
  overflow: hidden;

  flex-shrink: 0;
}

.option {
  padding: 16px;

  border-bottom: 1px solid $on-surface-color-50;
  white-space: pre-line;

  text-align: center;

  cursor: pointer;

  &:last-child {
    border: none;
  }

  &:hover {
    background-color: $primary-color-hover;
    color: $on-primary-color;
  }
}