@import '../../styles/variables';

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  position: relative;

  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: $content-max-width;

  margin: 0 auto;
  padding: 24px $padding;

  overflow: auto;
}

.infobox {
  height: auto;
  margin-bottom: 24px;

  p {
    margin: 10px 0;
  }

  .tipLabel {
    font-weight: 700;
    text-decoration: underline;
  }
}
