@import '../../styles/variables';

.container {
  position: relative;

  width: 100%;
  max-width: $content-max-width;

  border-radius: $border-radius;

  background-color: $background-color;
  box-shadow: $box-shadow;

  display: flex;

  cursor: pointer;

  margin-bottom: 20px;
}

.title {
  padding: 0 16px;
  font-size: $heading-2;
}

.arrow {
  flex: 0 0 10%;
  background-color: $primary-color;
  border-radius: 0 $border-radius $border-radius 0;

  display: flex;
  justify-content: center;
  align-items: center;
  color: $on-primary-color;
}

.content {
  flex: 1 1 auto;
  overflow: hidden;

  padding: 5px 0;
}
