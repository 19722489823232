@import '../../styles/variables';

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(51, 51, 51, 0.5);
}

.tapText {
  box-shadow: $box-shadow;
}
