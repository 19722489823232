@import '../../../styles/variables';

.container {
  width: 100%;
  padding: 8px;

  box-shadow: $box-shadow;
  border-radius: $border-radius;

  background-color: $surface-color;
  color: $on-surface-color;
  font-size: $body-2;

  .title {
    font-size: $caption;
    color: $on-surface-color-50;
  }

  p {
    margin: 0;
  }
}