@import '../../styles/variables';

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  
  transform: translate(-50%, -50%);

  width: 100%;
  max-width: 210px;

  border-radius: $border-radius;

  background-color: $primary-color;
  box-shadow: $box-shadow;
}

.title {
  padding: 16px;
  color: $on-primary-color;
}