@import '../../../styles/variables';

.container {
  display: flex;
  align-items: flex-end;

  width: 100%;
  max-width: 408px;

  margin: 15px 0;

  user-select: none;

  flex-shrink: 0;

  opacity: 0;
  transform: translateY(-30px);

  transition: opacity 0.15s ease-in, transform 0.15s ease-in;

  .portrait {
    flex-shrink: 0;
  }
}

.visible {
  opacity: 1;
  transform: translateY(0px);
}

.left {
  align-self: flex-start;

  .portrait {
    margin-right: 8px;
  }

  .bubble {
    background-color: $primary-color;
    color: $on-primary-color;
    border-radius: $border-radius $border-radius $border-radius 0;
  
    span {
      color: $on-primary-color-50;
    }
  }
}

.right {
  flex-direction: row-reverse;
  align-self: flex-end;

  .portrait {
    margin-left: 8px;
  }

  .bubble {
    background-color: $surface-color;
    color: $on-surface-color;
    border-radius: $border-radius $border-radius 0 $border-radius;
  
    span {
      color: $on-surface-color-50;
    }
  }
}