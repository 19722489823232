@import '../../styles/variables';

.container {
  width: 100%;
  max-width: $element-max-width;
  z-index: 1;
  button {
    width: 100%;
    padding: 16px;
  
    background-color: $primary-color;
    color: $on-primary-color;
  
    border-radius: $border-radius;
    box-shadow: $box-shadow;
  
    transform: translate3d(0, 0, 0);
    transition: transform 0.1s ease, box-shadow 0.1s ease, opacity 0.1s ease;
  
    text-transform: uppercase;
  
    cursor: pointer;
  
    &:hover {
      background-color: $primary-color-hover;
      color: $on-primary-color;
    }
  
    &:active {
      transform: translate3d(0, 2px, 0);
      box-shadow: $no-box-shadow;
    }
  }
}

.locked {
  pointer-events: none;

  button {
    opacity: 0.5;
  }
}