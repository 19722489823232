@import '../../styles/variables';

.container {
  position: absolute;
  top: 22px;
  left: $padding;
  right: $padding;
  bottom: 22px;

  display: flex;
  flex-direction: column;

  background-image: url('../../static//images/background-blurred.jpg');

  @include max-width-down {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.content {
  flex: 1;

  width: 100%;
  max-width: $content-max-width;

  margin: 0 auto;
  padding: $padding;
}

.button {
  margin-top: 16px;
}