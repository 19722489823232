@import '../../styles/variables';

.list {
  width: 100%;

  margin: 0;
  padding: 0;

  background-color: $surface-color;
  list-style: none;

  overflow: auto;
}

.panel {
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}