@import '../../styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  text-align: center;

  width: 100%;
  min-height: 288px;

  padding: $padding;

  background-color: $surface-color;
  border-radius: $border-radius;

  h2 {
    margin: 0;
  }

  p {
    margin: 5px 0;
  }

  i {
    font-size: 72px;
    margin-bottom: 10px;
  }
}

.portrait {
  margin-bottom: 10px;
}

.animation {
  margin-bottom: 10px;
}
