@import '../../styles/variables';

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: $padding;

  // firefox hack: https://bugzilla.mozilla.org/show_bug.cgi?id=748518
  padding-bottom: 0;
  &:after {
    content: '';
    display: block;
    padding-bottom: 100px;
  }

  background-color: $surface-color;

  line-height: 2;

  overflow: auto;

  h2 {
    margin: 8px 0;
  }

  hr {
    margin: 20px 0 -20px 0;

    border: none;
    border-bottom: 1px solid $on-surface-color-50;
  }
}

.hotspot {
  transform: translateY(4px);
  height: 18px;
}

.button {
  display: flex;
  justify-content: center;

  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  padding: 0 $padding 40px $padding;
}