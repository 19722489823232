@import '../../styles/variables';

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;

  background-color: $surface-color;
}

.content {
  position: relative;
  flex: 1;
}

.gochi {
  font-family: 'gochiHand', serif;
  font-size: 19px;
  z-index: 10;
}
