@import '../../styles/variables';

.container {
  position: absolute;
  top: 22px;
  left: $padding;
  right: $padding;
  bottom: 22px;

  display: flex;
  flex-direction: column;

  background-color: $surface-color;

  @include max-width-down {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  position: relative;

  .button {
    align-self: flex-end;

    padding: 0 $padding 20px $padding;
  }
}