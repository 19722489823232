@import '../../styles/variables';

.container {
  display: inline-block;
  font-size: 16px;
  width: 2em;
  height: 1em;
  top: -100px;

  margin: 0 5px;

  cursor: pointer;
  background-color: #FAE57B;
  opacity: 1;
  z-index: 0;
}

.icon {
  float: right;
  display: none;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  z-index: 100;
}

.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 101;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.menuClipboardItemSelected {
  color: $primary-color;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: -7px;
    left: -8px;
    bottom: -7px;
    right: -6px;
    border-radius: 50%;
    z-index: -1;
    border: 2px solid $primary-color-hover;
    box-shadow: 0px 0px 0px 5px rgba($primary-color-hover, 0.2);

    -webkit-animation: pulsate-fwd 1s ease-in-out infinite both;
    animation: pulsate-fwd 1s ease-in-out infinite both;
  }
}

@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
