@import '../../styles/variables';

.container {
  width: 100%;

  padding: 0 16px;

  background-color: $surface-color;
  border-radius: $border-radius;

  h2 {
    margin: 15px 0;
    text-align: start;
  }

  ul {
    margin: 0;
    padding: 0;

    list-style: none;
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    padding: 10px 0;
    border-top: 1px solid $on-surface-color-50;

    i {
      margin-right: 15px;
    }
  }
}

.correct {
  color: $correct-color;
}

.incorrect {
  color: $incorrect-color;
}