@import '../../styles/variables';

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1010;

  background-color: rgba(0, 0, 0, 0.5);

  overflow: auto;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: $content-max-width;

  margin: 0 auto;
  padding: 72px $padding;
}

.infobox {
  height: auto;
}

.button {
  margin-top: 24px;
}