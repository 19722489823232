@import '../../styles/variables';

.container {
  position: absolute;
  top: 0;
  left: -80%;
  right: 0;
  bottom: 0;

  max-width: 80%;

  display: flex;
  flex-direction: column;

  background-color: $container-color;

  transition: left 0.3s ease-in-out;
}

.slideIn {
  left: 0;
}

.content {
  flex: 1;
  overflow: auto;
}