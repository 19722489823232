@import '../../styles/variables';

.container {
  width: 100%;
  max-width: 300px;

  background-color: $surface-color;

  box-shadow: 0 0 5px $on-surface-color-50;
}

.display {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 50px;

  padding: 10px $padding;

  background-color: $surface-color;
  border-bottom: 1px solid;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.buttonsContainer {
  display: grid;
  grid-template-areas:  "h h h h h h h"
                        "h h h h h h h"
                        "h h h h h h h"
                        "h h h h h h h"
                        "h h h h h h h";
  grid-gap: 0;
  margin: $padding/2;

  button {
    padding: $padding/1.7;

    background-color: $surface-color;
    font-size: 21px;
    color: $primary-color;

    cursor: pointer;

    transition: box-shadow 0.15s ease, transform 0.15s ease;
    border-radius: 40px;

    &:hover {
      background-color: $primary-color-hover;
      color: $on-primary-color;
    }

    &:active {
      transform: translateY(2px);
      box-shadow: $no-box-shadow;
    }

    &.selected {
      background-color: $primary-color-hover !important;
      color: $on-primary-color !important;
    }
  }

  .primary {
    color: $primary-color;
  }
}

.month {
  padding: 0 $padding*5;
}

.chevrons {
  cursor: pointer;
}

.submitButton {
  width: 100%;
  background-color: $primary-color;
  padding: $padding;
  color: $on-primary-color;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  &:hover {
    box-shadow: 0 -1px $on-surface-color-50;
    cursor: pointer;
    background-color: $primary-color-hover;
    color: $on-primary-color;
  }
}

.optionButton {
  width: 100%;
  background-color: $surface-color;
  padding: $padding;
  box-shadow: 0 -1px $on-surface-color-50;

  &:hover {
    cursor: pointer;
    background-color: $primary-color-hover;
    color: $on-primary-color;
  }
}
