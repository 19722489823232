@import '../../styles/variables';

.container {
  position:  absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 24px $padding 100px 24px;

  background-color: $surface-color;

  textarea {
    width: 100%;
    height: 100%;

    line-height: 1.5;

    border: none;
    outline: none;

    resize: none;
  }
}

.button {
  display: flex;
  justify-content: center;

  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1001;

  padding: 0 $padding 20px $padding;
}