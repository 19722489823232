@import '../../styles/variables';

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  // firefox hack: https://bugzilla.mozilla.org/show_bug.cgi?id=748518
  &:after {
    content: '';
    display: block;
    padding-bottom: 100px;
  }

  background-color: $surface-color;

  overflow: auto;
  z-index: 0;
  .imgContainer {
    position: relative;
    overflow: hidden;

    & > span {
      position: absolute;
      transform: translateY(-50%);
    
      @include max-width-down {
        font-size: 2.7vw;
      }
    }
  }

  img {
    width: 100%;
    pointer-events: none;
    user-select: none;
  }

  h2 {
    margin: 24px 0 10px 0;
  }

  .subtitle {
    display: block;
    padding: 0px 15px;
    text-align: center;
    font-weight: 500;
  }
}

.button {
  display: flex;
  justify-content: center;

  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  padding: 0 $padding 40px $padding;
}

.label {
  @include max-width-down {
    font-size: 2.7vw;
  }
}

.child {
  position: absolute;

  @include max-width-down {
    font-size: 2.7vw;
  }
}

.hotspot {
  font-size: 14px;

  @include max-width-down {
    font-size: 2vw;
  }
}
