@import '../../styles/variables';

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: $padding;

  background-image: url('../../static/images/background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .button {
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);

    max-width: 288px;

    button {
      font-size: 32px;
      font-weight: 600;
      text-transform: initial;
    }
  }
}

.logoContainer {
  position: absolute;
  top: 0;
  left: 24px;
  z-index: 10;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 105px;
  height: 120px;

  background-color: $surface-color;

  @include max-width-down {
    width: 63px;
    height: 76px;
  }

  img {
    width: 90%;
  }
}

.titleContainer {
  display: flex;
  justify-content: flex-start;

  position: absolute;
  top: 100px;
  right: 0;

  width: 100%;
  max-width: 528px;
  padding: 0 24px;

  background-color: $primary-color-80;


  h1 {
    font-size: 64px;
    color: $on-primary-color;
    text-align: left;
    line-height: 1.12;
  }

  @include max-width-down {
    top: 62px;
    justify-content: center;

    h1 {
      font-size: 48px;
      text-align: center;
    }
  }
}

.portraits {
  position: absolute;
  bottom: 120px;
  left: 50%;
  transform: translateX(-50%);

  width: 80%;
  max-width: 400px;
}
