@import '../../styles/variables';

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .content {
    max-width: 386px;
    padding: $padding;

    overflow: auto;
  }

  h2 {
    color: $on-primary-color;

    margin-bottom: 20px;
  }
}

.input {
  margin-top: 40px;
}

.button {
  margin-top: 60px;
}