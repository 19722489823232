@import '../../styles/variables';

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  flex: 1;

  position: relative;

  width: 100%;

  margin: 0 auto;
  padding: $padding;
}