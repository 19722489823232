@import '../../styles/variables';

.label {
  color: $primary-color;

  cursor: pointer;
  z-index: 10;
  &:hover {
    color: $primary-color-hover;
  }
}

.gochi {
  color: $correct-color;

  cursor: pointer;
  z-index: 10;
  &:hover {
    color: $correct-color;
  }
}

.inactive {
  pointer-events: none;

  color: $on-surface-color;

  cursor: default;

  &:hover {
    color: $on-surface-color;
  }
}