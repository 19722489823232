@import '../../styles/variables';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  i {
    font-size: 64px;
    color: $on-primary-color;
    cursor: pointer;

    &:hover {
      color: $primary-color-hover;
    }
  }
}