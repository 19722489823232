.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
}

.content {
  position: relative;
  flex: 1;

  width: 100%;

  overflow: hidden;
}

.imageContainer {
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }
}

.portrait {
  position: absolute;
  transform: translate(-50%, -50%);
}