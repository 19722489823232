@import '~normalize.css';

@import 'styles/variables';

body {
  margin: 0;
  font-family: FFDINStd, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $background-color;

  font-size: $body-1;
  color: $on-surface-color;

  line-height: 1.5;
  
  -webkit-tap-highlight-color: transparent;
}

* {
  box-sizing: border-box;

  &::selection {
    background-color: $primary-color;
    color: $on-primary-color;
  }
}

h1 {
  font-size: $heading-1;
  text-align: center;
}

h2 {
  margin: 10px 0;
  font-size: $heading-2;
  text-align: center;
}

h3 {
  margin: 0;
  font-size: $heading-3;
}

div,
button,
span,
p,
i,
h1,
h2,
h3 {
  border: none;
  outline: none;
}

a {
  color: $primary-color;

  &:visited {
    color: $primary-color;
  }

  &:hover {
    color: $primary-color-hover;
  }
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: 40px 0;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  @include max-width-down {
    padding: 0;
  }
}

#container {
  position: relative;

  width: 100%;
  height: 100%;
  max-width: $max-width;
  max-height: $max-height;

  background-image: url('./static/images/background-blurred.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  overflow: hidden;
}

.tip-label {
  font-weight: 700;
  text-decoration: underline;
}
