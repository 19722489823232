@import '../../styles/variables';

.container {
  position: relative;

  width: 100%;
  max-width: $element-max-width;
  border-radius: $border-radius;

  background-color: $surface-color;
  display: flex;
  align-items: center;

  input {
    width: 100%;

    padding: 16px;

    border: none;
    outline: none;
    background-color: transparent;
    color: $on-surface-color;

    &:focus {
    }
  }

  label {
    position: absolute;
    top: 13px;
    left: 16px;

    pointer-events: none;
    transition: transform .15s, left .15s, color .15s, font-size .15s;
  }

  .slideUp {
    transform: translateY(-34px);
    left: 0px;
    color: $on-primary-color;
    font-size: $caption;
  }

  .icon {
    padding: 10px;
    font-size: 25px;
  }
}
