@import '../../styles/variables';

.container {
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: $padding;

  // firefox hack: https://bugzilla.mozilla.org/show_bug.cgi?id=748518
  padding-bottom: 0;
  &:after {
    content: '';
    display: block;
    padding-bottom: $padding;
  }

  overflow: auto;
}

.choice {
  width: 100%;
  max-width: $content-max-width;

  margin: 0 auto;
  padding: 0 $padding 24px $padding;

  opacity: 1;

  transition: opacity 0.15s ease-in-out, padding 0.15s ease-in-out;
}

.hide {
  opacity: 0;
  padding: 0;
}