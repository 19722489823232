@import '../../styles/variables';

.container {
  display: flex;
  flex-direction: column;

  width: 100%;

  margin-top: 16px;
  padding: 0 16px;

  background-color: $surface-color;
  border-radius: $border-radius;

  overflow: hidden;

  h2 {
    margin: 5px 0;
    text-align: center;
  }

  ol {
    margin: 0;
    padding: 0;

    list-style: none;

    overflow: auto;
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 10px 0;
    border-top: 1px solid $on-surface-color-50;
  }
}

.rank {
  padding-right: 10px;
}

.correct {
  color: $correct-color;
}

.incorrect {
  color: $incorrect-color;
}

.active {
  color: $primary-color;
}

.score {
  float: right;
}