@import '../../styles/variables';

.container {
  display: flex;
  flex-direction: column;
  margin: $padding;
  padding: $padding;
  border-radius: $border-radius;
  background-color: $background-color;

  h2 {
    margin: 0 0 16px 0;
    font-weight: 400;
    text-align: left;
    font-size: $body-1;
  }

  input {
    width: 100%;
    padding: $padding;

    outline: none;
    border: none;
    border-radius: $border-radius;
  }
}

.label {
  float: left;
  margin: 8px 8px 0 0;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}

.button {
  width: auto;
  font-size: $body-2;

  button {
    padding: 10px 18px;
  }
}