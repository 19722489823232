@import '../../styles/variables';

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;

  min-height: 58px;

  padding: 8px;

  background-color: $surface-color;
  border-radius: $border-radius;
  box-shadow: $box-shadow;

  transform: translate3d(0, 0, 0);
  transition: transform 0.1s ease, box-shadow 0.1s ease, opacity 0.1s ease;

  cursor: pointer;

  &:hover {
    background-color: $primary-color-hover;
  }

  &:active {
    transform: translate3d(0, 2px, 0);
    box-shadow: $no-box-shadow;
  }

  &.inactive {
    opacity: 0.8;
    background-color: $on-surface-color-20;
    pointer-events: none;
  }

  p {
    margin: 0;

    color: $on-surface-color-50;
    font-size: $caption;
  }
}