@import '../../styles/variables';

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;

  width: 100%;
  max-width: $content-max-width;

  margin: 0 auto;
  padding: 24px $padding 24px $padding;

  overflow: auto;
}

.home {
  i:first-child {
    color: $primary-color-hover;
  }
}

.screen {
  i:nth-child(2) {
    color: $primary-color-hover;
  }
}

.document {
  i:nth-child(3) {
    color: $primary-color-hover;
  }
}

.joker {
  img {
    filter: none;
    border: 1px solid $primary-color;
  }
}

.hideHighscore {
  span {
    display: none;
  }
}

.highlightHighscore {
  span {
    color: $primary-color;
  }
}