$heading-1: 34px;
$heading-2: 24px;
$heading-3: 16px;

$body-1: 16px;
$body-2: 14px;

$caption: 12px;

$icon-1: 96px;
$icon-2: 64px;
$icon-3: 24px;
$icon-4: 16px;